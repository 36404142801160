import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@mui/lab";
import { ShipmentRepository } from "../../repository";
import _ from "lodash";
import moment from "moment";

export const OrderTrackingPage = () => {
  const location = useLocation();
  const [trackingNumber, setTrackingNumber] = useState(location.state?.trackingNumber ?? "");
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [allData, setAllData] = useState("");

  useEffect(() => {
    getTrackingData();
  }, []);
  const getTrackingData = async () => {
    const response = await ShipmentRepository.getTrackingDetails({
      tracking_number: trackingNumber
    });
    if (response) {
      if (response?.status_code == 1) {
        setTrackingDetails(response?.data?.completeTrackResults?.[0]?.trackResults?.[0]?.scanEvents || []);
        setAllData(response?.data?.completeTrackResults?.[0]?.trackResults?.[0]);
      } else {
        setTrackingDetails([]);
      }
    } else {
      setTrackingDetails([]);
    }
  };
  return (
    <Container sx={{ mt: 3, mb: 3 }}>
      <Grid container justifyContent={"space-between"} mb={2}>
        <Typography variant="h6" fontFamily={"Source Sans Pro"} fontWeight={600}>
          {_.get(allData, "trackingNumberInfo.carrierCode", "")} Tracking Number: {trackingNumber}
        </Typography>
        <Typography variant="h6" fontWeight={500} fontFamily={"Source Sans pro"}>
          Shipping Carrer:  {_.get(allData, "serviceDetail.description", "")}
        </Typography>
      </Grid>
      <Stack spacing={2} mb={2}>
        <Card sx={{ borderRadius: 3 }} m={1}>
          <CardContent>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Box>
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      fontFamily={"Source Sans pro"}
                    >
                      Recipient
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={500}
                      fontFamily={"Source Sans pro"}
                    >
                      {allData.length == 0 ? '':(<>
                      {`${_.get(allData, "recipientInformation.address.city", "")}, 
                      ${_.get(allData, "recipientInformation.address.stateOrProvinceCode", "")}, 
                      ${_.get(allData, "recipientInformation.address.countryName", "")}`}
                      </>)}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Stack>

      <Card sx={{ borderRadius: 3 }} m={1}>
        <CardContent>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            fontWeight={600}
            mb={2}
          >
            Order Tracking Timeline
          </Typography>
          {trackingDetails.length == 0 ?
            <Typography
              variant="subtitle1"
              fontWeight={500}
              fontFamily={"Source Sans pro"}
              style={{ textAlign: 'center' }}
            >
              Tracking is not available yet..!
            </Typography>
            :
            <Timeline position="alternate">
              {_.map(trackingDetails, (item, index) => {
                return (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color={index == 0 ? 'success' : index == (trackingDetails.length - 1) ? 'error' : 'primary'} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body1" fontWeight={600}>
                        {item?.eventDescription}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {moment(item?.date).format("MMM D, YYYY - h:mm A")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          }
        </CardContent>
      </Card>
    </Container>
  );
};
