import React from "react";
import Button from "@mui/material/Button";

export default function ButtonComponent({
  size = "medium",
  name,
  variant = "outlined",
  onClick = () => {},
  width,
  disabled = false
}) {
  return (
    <Button
      size={size}
      sx={{
        textTransform: "capitalize",
        fontFamily: "Source sans pro",
        borderRadius: 2,
        minWidth: 100,
        width:width,
        textAlign:'center'
      }}
      color={variant === "outlined" ? "secondary" : "primary"}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </Button>
  );  
}
