import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, CardMedia } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { IMAGES } from "../../assets";
import {
  ButtonComponent
} from "../../components/atoms";

export default function ReturnPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resultIndicator = queryParams.get("resultIndicator") || "Unknown";
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        setIsLoading(true);
        let dataset = { resultIndicator: resultIndicator };
        const response = await axios.post('http://localhost:8000/api/payment/get-pay-details', dataset);
        console.log('req:', response);
        setMessage(response.data.message)
        setIsLoading(false);
        if (response.data.status_code == 0) {
        } else {

          setResult(response.data.data.result);
        }
      } catch (error) {

      }

    }
    getOrderDetails();
  }, []);

  const buttonNavigation = () => {
    result == 'SUCCESS' ? navigate("/history/transactions") :   navigate("/my-suite")
  };

  return (
    <Container sx={{ mt: 5, mb: 5 }}>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, alignItems: "center" }}>
        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          color={"#572682"}
          align="center"
        >
          {message}
          {/* Payment Successful! Thank you for your purchase. */}
        </Typography>
        {result == 'SUCCESS' ?
          <CardMedia
            component="img"
            alt="SUCCESS"
            image={IMAGES.LOGO.SUCCESS_ICON}
            sx={{ backgroundSize: "contain", width: 170 }}
          />
          : result == 'FAILURE' ?
            <CardMedia
              component="img"
              alt="SUCCESS"
              image={IMAGES.LOGO.CROSS_ICON}
              sx={{ backgroundSize: "contain", width: 170 }}
            />
            : result == 'PENDING' || result == 'UNKNOWN' ? <CardMedia
              component="img"
              alt="SUCCESS"
              image={IMAGES.LOGO.INFO_ICON}
              sx={{ backgroundSize: "contain", width: 170 }}
            /> : ''}

        <ButtonComponent
          variant="contained"
          name={isLoading ? "Processing..." : result == 'SUCCESS' ? 'Check your transcation':'Go to My Suite'}
          onClick={buttonNavigation}
          disabled={isLoading}
        />
      </Box>
    </Container>
  );
}
