import React, { useState, useEffect } from "react";
import { Card, CardContent, Divider, Grid } from "@mui/material";
import { ButtonComponent, LableValueCardlet } from "../../atoms";
import NoticeCard from "./NoticeCard";
import { useNavigate } from "react-router-dom";

export default ({
  cost = 0.0,
  weight = 0.0,
  packages = 0,
  selectedItems = [],
  weightUnit = '',
  lateCharge = 0,
  lateChargeData = []
}) => {
  const navigate = useNavigate();
  const [isEmptySelected, setIsEmptySelected] = useState(false);
  let totalAmount = parseFloat(cost) + parseFloat(lateCharge);
  const onClickCreateShipRequest = () => {
    if(selectedItems.length > 0) {
        navigate("/ship-request-page", { state: { selectedItems: selectedItems, totalCost: cost, totalWeight:weight, weightUnit:weightUnit, lateCharge:lateCharge, lateChargeData:lateChargeData} });
    }else{
        setIsEmptySelected(true);
    }   
  };

  const roundToDecimalPlace = (number, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(number * factor) / factor;
  };

  const getLateDaysSum = () => {
    const totalLateDays = lateChargeData.reduce((sum, item) => {
      return sum + (item.lateDays || 0); // Add lateDays if it exists, otherwise add 0
    }, 0);
    return totalLateDays;
  };
  return (
    <Grid container spacing={1}>
      <Grid item xl={12} lg={12} md={12} sm={6} xs={12}>
        <Card variant="outlined" sx={{ width: "100%" }}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <CardContent sx={{ width: "100%" }}>
                <Grid container flexDirection={"column"} spacing={0}>
                  <LableValueCardlet lable="Shipping Cost" value={`$ ${roundToDecimalPlace(cost, 2)}`} />
                  <LableValueCardlet lable="Total Weight" value={`${weightUnit== 'lbs' ? 'L' : 'Kg'} ${roundToDecimalPlace(weight, 2)}`} />
                  <LableValueCardlet
                    lable="Packages"
                    value={packages < 10 ? `0${packages}` : packages}
                  />
                  {lateCharge > 0 ? 
                     <LableValueCardlet
                     lable={`Late Charges - For ${getLateDaysSum()} days`}
                     value={`$ ${roundToDecimalPlace(lateCharge, 2)}`}
                   />
                  :''}
               
                </Grid>
              </CardContent>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <CardContent sx={{ width: "100%" }}>
                <Grid
                  container
                  flexDirection={"column"}
                  rowSpacing={2}
                  spacing={0}
                >
                  <LableValueCardlet lable="Total Value" value={`$ ${roundToDecimalPlace(totalAmount, 2)}`} />
                  <LableValueCardlet lable="Total Weight" value={`${weightUnit== 'lbs' ? 'L' : 'Kg'} ${roundToDecimalPlace(weight, 2)}`}/>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ButtonComponent
                      name={"CREATE SHIP REQUEST"}
                      variant="contained"
                      onClick={onClickCreateShipRequest}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={6} xs={12}>
        <NoticeCard />
      </Grid>
    </Grid>
  );
};
