import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { ProhibitedItems } from "../../components/organisms";
export const ProhibitedItemsPage = () => {
  return (
    <Container sx={{ mt: 2, mb: 2 }}>
      <ProhibitedItems/>
    </Container>
  );
};
