import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableComponent } from "../../../atoms";
import { ShipRequestCard } from "../../../modules";

export default ({
  columns = [],
  title = "",
  data = [],
  selectableRows = "none",
  expandableRows = true,
  onHandleBackDrop = () => {},
  onLoadDataAgain = () => {},
  onRowSelectionChange = () => {},
  totalWeight,
  totalShippingCost,
  packages,
  seletedItems = [],
  weightUnit,
  lateCharge = 0,
  lateChargeData = [],
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        <TableComponent
          columns={columns}
          title={title}
          data={data}
          selectableRows={selectableRows}
          expandableRows={expandableRows}
          onHandleBackDrop={onHandleBackDrop}
          onLoadDataAgain={onLoadDataAgain}
          onRowSelectionChange={onRowSelectionChange}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <ShipRequestCard
          cost={totalShippingCost}
          weight={totalWeight}
          packages={packages}
          selectedItems={seletedItems}
          weightUnit={weightUnit}
          lateCharge={lateCharge}
          lateChargeData={lateChargeData}
        />
      </Grid>
    </Grid>
  );
};
