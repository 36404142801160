import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ButtonComponent,
  DropdownComponent,
  PopupComponent,
  TableComponent,
} from "../../components/atoms";
import { AddressCard, ShippingDetailsCard } from "../../components/modules";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CommonRepositoty,
  DashBoardRepository,
  ShipmentRepository,
  UserRepository,
} from "../../repository";
import _, { get, partialRight } from "lodash";
import { ErrorMessage, SuccessMessage } from "../../components/organisms";

export const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState(
    _.get(location, "state.selectedItems")
  );
  const [addressList, setAddressList] = useState([]);
  const [addressListDropdown, setAddressListDropdown] = useState([]);
  const [SelectedAddress, setSelectedAddress] = useState({});
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState("");
  const promoDetails = useRef({});
  const [shippingChargers, setShippingChargers] = useState([]);
  const [shippingCost, setShippingCost] = useState(
    _.get(location, "state.totalCost")
  );
  const [weightUnit, setWeightUnit] = useState(
    _.get(location, "state.weightUnit")
  );
  const [lateFee, setLateFee] = useState(
    _.get(location, "state.lateCharge")
  );
  const [lateFeeDetails, setLateFeeDetails] = useState(
    _.get(location, "state.lateChargeData")
  );
  const [check, setCheck] = useState(false);
  const [radioButton, setRadioButton] = useState("");
  const totalAddonCost = useRef(0);
  // const [totalAddonCost, setTotalAddonCost] = useState(0);
  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");
  const [discountValue, setDiscountValue] = useState("0");
  const [onlyShipmentCharge, setOnlyShipmentCharge] = useState("");
  const [selectedOtherCharges, setSelectedOtherCharges] = useState([]); 
  const [successResMessage, setSuccessResMessage] = useState("");
  const [isResSuccess, setResSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPromoDisabled, setIsPromoDisabled] = useState(false);

  useEffect(() => {
    getUserAddresses();
    getActivePaymentGateways();
    getAdditionalShippingChargers();
    setOnlyShipmentCharge(shippingCost)
    setShippingCost(parseFloat(shippingCost)+parseFloat(lateFee));

  }, []);

  const column = [
    {
      name: "packageStatus",
      label: "State",
      options: {
        display: false,
      },
    },
    {
      name: "etailerName",
      label: "From",
    },
    {
      name: "packageId",
      label: "Package ID",
    },
    {
      name: "total",
      label: "Merchandise Value",
    },

    {
      name: "weightWithUnit",
      label: "Weight",
    },

    {
      name: "id",
      label: "Remove",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ButtonComponent
            variant="outlined"
            name={"remove"}
            onClick={() => handleRemove(value)}
          />
        ),
      },
    },

    // {
    //   name: "packageArrivalDateAtWarehouse",
    //   label: "From",
    //   options: {
    //     customHeadLabelRender: ({ display, column }) => {
    //       return (
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //           }}
    //         >
    //           <AccessAlarmIcon />
    //         </Box>
    //       );
    //     },
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <p>{dateCount(value)}</p>
    //     ),
    //   },
    // },
  ];

  const dateCount = (date) => {
    const moment = require("moment");
    const givenDate = moment(date);
    const currentDate = moment();
    return currentDate.diff(givenDate, "days") + 1;
  };


  const handleAdditionalCost = (isChecked, details) => {
    // const checked = _.get(index, "target.checked");
    // let add = 0;
    let totalShippingCost = parseFloat(onlyShipmentCharge) + parseFloat(lateFee) + parseFloat(totalAddonCost.current);

    if(isChecked){
      totalShippingCost = parseFloat(totalShippingCost) + details.charge;
      totalAddonCost.current += details.charge;
    }else{
      totalShippingCost = parseFloat(totalShippingCost) - details.charge;
      totalAddonCost.current -= details.charge;
    }

    if (!_.isEmpty(promoDetails.current)) {
      const promoType = _.get(promoDetails.current, "type_name", "");
      const promoValue = parseFloat(_.get(promoDetails.current, "value", 0));

      if (promoType === "flat") {
        totalShippingCost -= promoValue;
      } else if (promoType === "percentage") {
        setDiscountValue((promoValue / 100) * totalShippingCost)
        totalShippingCost -= (promoValue / 100) * totalShippingCost;
      }
    }
    setShippingCost(totalShippingCost);

    setSelectedOtherCharges((prevSelected) => {
      if (isChecked) {
        // Add selected charge if checked
        return [...prevSelected, details];
      } else {
        // Remove charge if unchecked
        return prevSelected.filter((item) => item.code !== details.code);
      }
    });
  };

  const getShippingCost = async (totalWeight, unit, packagesData) => {
    const response = await CommonRepositoty.onGetShippingCost({
      packageFrom: "USA",
      unit: unit,
      weight: totalWeight,
    });
      if (response?.status_code == 1) {
        let newShippingPrice = parseFloat(
          _.get(response, "data.rates[0].shippingCost", [])
        );

        let totalPenaltyAmt = 0;
        const lateFeeData = [];
        setLateFee(0);
        setLateFeeDetails([]);

        packagesData.forEach((packageDetails, index) => {
          if(packageDetails.daysCount > 30){
            let penaltyAmount = 0;
            if(packageDetails.daysCount <= 60){
              penaltyAmount = 1 * (packageDetails.daysCount - 30);
            }else if(packageDetails.daysCount <= 90){
              penaltyAmount = 30 + (2 * (packageDetails.daysCount - 60));
            }else{
              penaltyAmount = 30 + 60 + (3 * (packageDetails.daysCount - 90));
            }
            totalPenaltyAmt = parseFloat(totalPenaltyAmt) + parseFloat(penaltyAmount);
            let lateDays = packageDetails.daysCount - 30;
            
            lateFeeData.push({
              packageId: packageDetails.packageId,
              lateFee: penaltyAmount.toFixed(2),
              lateDays,
            });
          } 
         });
         setLateFee(parseFloat(totalPenaltyAmt).toFixed(2));
         setLateFeeDetails(lateFeeData);

         let totalShippingCost = newShippingPrice + totalPenaltyAmt;
         const baseShippingCost = newShippingPrice;

         if (totalAddonCost.current > 0) {
          totalShippingCost += totalAddonCost.current;
        }
        if (!_.isEmpty(promoDetails.current)) {
          const promoType = _.get(promoDetails.current, "type_name", "");
          const promoValue = parseFloat(_.get(promoDetails.current, "value", 0));
  
          if (promoType === "flat") {
            totalShippingCost -= promoValue;
          } else if (promoType === "percentage") {
            setDiscountValue((promoValue / 100) * totalShippingCost)
            totalShippingCost -= (promoValue / 100) * totalShippingCost;
          }
        }
  
        // Update state
        setShippingCost(totalShippingCost.toFixed(2));
        setOnlyShipmentCharge(baseShippingCost.toFixed(2));


        // if (totalAddonCost.current <= 0 && _.isEmpty(promoDetails.current)) {
        
        //   setShippingCost(newShippingPrice + parseFloat(totalPenaltyAmt));
        //   setOnlyShipmentCharge(newShippingPrice);
        // }else{

        // }
        // if (totalAddonCost.current > 0) {

        //   let total = newShippingPrice + totalAddonCost.current + parseFloat(totalPenaltyAmt);
        //   setShippingCost(total);
        //   setOnlyShipmentCharge(newShippingPrice);
        // }
        // if (!_.isEmpty(promoDetails.current)) {
        //   if (_.get(promoDetails.current, "type_name", {}) == "flat") {
        //     let discounted =
        //       shippingCost -
        //       parseFloat(_.get(promoDetails.current, "value", {}));
        //     setShippingCost(discounted + parseFloat(totalPenaltyAmt));
        //   }
        //   if (_.get(promoDetails.current, "type_name", {}) == "percentage") {
        //     let percentage =
        //       (parseFloat(_.get(promoDetails.current, "value", {})) / 100) *
        //       parseFloat(shippingCost);
        //     let discounted = parseFloat(shippingCost) - percentage;
        //     setShippingCost(discounted + parseFloat(totalPenaltyAmt));
        //   }
        // }
       
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
    
  };

  const handleRemove = (id) => {
    const updatedList = _.filter(selectedItems, (item) => {
      if (_.get(item, "id") !== id) {
        return item;
      }
    });

    let weightt = 0;
    _.forEach(updatedList, (item) => {
      weightt += parseFloat(item?.weight);
    });
 
    const selectedPackageData = updatedList.map((item) => ({
      packageId: item.packageId,
      daysCount: dateCount(item?.packageArrivalDateAtWarehouse),
    }));
    getShippingCost(weightt, weightUnit, selectedPackageData);

    setSelectedItems(updatedList);
  };

  const onChanagePromoCode = (value) => {
    setPromoCode(_.get(value, "target.value"));
  };

 

  const onSelectAddress = (value) => {
    setSelectedAddressId(value?.target?.value);
    const address = _.filter(addressList, (item, index) => {
      if (item.id === value?.target?.value) {
        return item;
      }
    });
    setSelectedAddress(address[0]);
  };

  const getUserAddresses = async () => {
    const response = await UserRepository.getApprovedAddressList("");
      if (response?.status_code == 1) {
        const list = _.map(_.get(response, "data", []), (item, index) => {
          return { id: item.id, name: item.lable };
        });
        setAddressList(_.get(response, "data", []));
        setAddressListDropdown(list);
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
    
  };

  const getActivePaymentGateways = async () => {
    const response = await DashBoardRepository.onGetActivePaymentGateways();
      if (response?.status_code == 1) {
        setPaymentMethods(_.get(response, "data", []));
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
   
  };

  const getAdditionalShippingChargers = async () => {
    const response = await ShipmentRepository.onGetAdditionalShippingChargers();
      if (response?.status_code == 1) {
        setShippingChargers(_.get(response, "data", []));
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
        // console.log("error response", response);
      }
    
  };

  const checkPromoCode = async (promoCode) => {
    const response = await ShipmentRepository.checkPromoCode({
      promo_code: promoCode,
    });
    if (response?.status_code == 1) {
      setCheck(true);
      promoDetails.current = _.get(response, "data", {});
      if (_.get(response, "data.type_name", {}) == "flat") {
        setDiscountValue(_.get(response, "data.value", {}));
        let discounted =
          shippingCost - parseFloat(_.get(response, "data.value", {}));
        setShippingCost(discounted);
      }
      if (_.get(response, "data.type_name", {}) == "percentage") {
        let percentage =
          (parseFloat(_.get(response, "data.value", {})) / 100) * shippingCost;
          setDiscountValue(percentage);
        let discounted = shippingCost - percentage;
        setShippingCost(discounted);
      }
      setIsPromoDisabled(true);
    } else {
      setPromoCode("");
      setResError(true);
      setErrorResMessage(_.get(response, "message", ""));
    }
  };

  const roundToDecimalPlace = (number, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(number * factor) / factor;
  };

  const onClickCheckBtn = () => {
    if (promoCode == "" || promoCode == null) {
      setPromoCodeError(true);
      setPromoCodeErrorMessage("Promo code not valid, please try again");
    } else {
      setPromoCodeError(false);
      checkPromoCode(promoCode);
    }
  };

  const handleChangeRadio = (value) => {
    setRadioButton(value);
  };

  // submit shipment request
  const submitShipmentRequest = async () => {

    let packages = selectedItems.map(item => item.packageId);
    let totalWeight = 0;
    totalWeight = selectedItems.reduce((acc, item) => acc + parseFloat(item.weight || 0), 0);

    if(packages.length == 0){
      setResError(true);
      setErrorResMessage("Please select packages");
    }else if(selectedAddressId == -1 || selectedAddressId == ""){
      setResError(true);
      setErrorResMessage("Please select shipping address");
    }else if(radioButton == ""){
      setResError(true);
      setErrorResMessage("Please select payment method");
    }else{
      setIsLoading(true);
      try {
        const response = await ShipmentRepository.submitShipmentRequest({
          packages: packages,
          addressId: selectedAddressId,
          totalWeight : totalWeight,
          shippingType : 'fed_ex',
          paymentType : radioButton,
          promoCode : promoCode ?? "",
          discount: parseFloat(discountValue).toFixed(2) ?? "",
          shippingCharge : onlyShipmentCharge,
          totalShipmentCost: shippingCost,
          additionalServices : selectedOtherCharges ?? [],
          lateFee: lateFee ?? 0,
          lateFeeDetails: lateFeeDetails ?? []
        });
        if (response?.status_code == 1) {
          setResSuccess(true);
          setSuccessResMessage(_.get(response, "message", ""))
        } else if(response?.status_code == 0) {
          setResError(true);
          setErrorResMessage(_.get(response, "message", ""));
        }
      } catch (error) {
        setResError(true);
        setErrorResMessage("An unexpected error occurred. Please try again later.");
      } finally {
        setIsLoading(false); 
      }
    }
  };

  return (
    <Container sx={{ pt: 5, pb: 5 }}>
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Stack>
            <TableComponent
              title="Packages in this Shipment"
              columns={column}
              data={selectedItems}
              selectableRows={false}
            />

            <Grid container mt={3}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <DropdownComponent
                  label="Shipping To"
                  value={selectedAddressId}
                  dropdownList={addressListDropdown}
                  disabled={false}
                  onChange={onSelectAddress}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item >
                {selectedAddressId > -1 ? (
                  <AddressCard
                    item={SelectedAddress}
                    showBackBtn={false}
                    showRadio={false}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      fontFamily={"Source Sans Pro"}
                    >
                      Estimated Shipping Cost
                    </Typography>
                    <Box>
                      <Typography variant="h6" fontFamily={"Source Sans Pro"}>
                        ${roundToDecimalPlace(shippingCost, 2)}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <ButtonComponent
                          variant="contained"
                          name={isLoading ? "Processing..." : "Confirm & Pay"} 
                          onClick={submitShipmentRequest}
                          disabled={isLoading}
                        />
                        {/* <ButtonComponent variant="outlined" name={"Cancel"} /> */}
                      </Box>
                    </Box>
                    <Typography
                      variant="caption"
                      fontFamily={"Source Sans Pro"}
                      color={"GrayText"}
                    >
                      Note - Please select a payment method to continue
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <ShippingDetailsCard
            promoValue={promoCode}
            promoCodeError={promoCodeError}
            promoCodeErrorMessage={promoCodeErrorMessage}
            onChangePromo={(value) => onChanagePromoCode(value)}
            shippingCost={shippingCost}
            shippingCharge={onlyShipmentCharge}
            paymentMethods={paymentMethods}
            otherOptions={shippingChargers}
            handleAdditionalCost={handleAdditionalCost}
            onClickCheck={onClickCheckBtn}
            isDiscount={check}
            promotion={promoDetails.current}
            radioButton={radioButton}
            handleChangeRadio={(value) => handleChangeRadio(value)}
            lateFee={lateFee}
            lateDetails={lateFeeDetails}
            isCheckDisabled={isPromoDisabled}
            handleShipmentSubmit={submitShipmentRequest}
            shipBtnName={isLoading ? "Processing..." : "Confirm & Pay"}
            shipBtnIsDisabled={isLoading}
          />
        </Grid>
      </Grid>

      <PopupComponent
        maxWidth={"sm"}
        actionButtonName={"OK"}
        content={<ErrorMessage message={errorResMessage} />}
        showSecondaryBtn={false}
        open={isResError}
        title={"Error"}
        handleAction={() => setResError()}
        handleClose={() => setResError()}
      />

    <PopupComponent
      maxWidth={"sm"}
      actionButtonName={"Go to home"}
      content={<SuccessMessage message={successResMessage} />}
      showSecondaryBtn={false}
      open={isResSuccess}
      title={"Shipment request"}
      handleAction={() => {
        setResSuccess(false); 
        navigate("/my-suite"); 
      }}
      // handleClose={() => setResSuccess(false)}
    />
    </Container>
  );
};
