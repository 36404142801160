import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { AddressCard } from "../../../modules";
import ButtonComponent from "../../../atoms/Buttons/ButtonComponent";
import { PopupComponent } from "../../../atoms";
import CreateAddress from "../../Forms/CreateAddress/CreateAddress";
import { UserRepository } from "../../../../repository";
import _ from "lodash";
import { SuccessMessage } from "../SuccessMessage/SuccessMessage";

export default function AddressView() {
  const [viewAddressForm, setViewAddressForm] = useState(false);
  const addressRef = useRef();

  const [isSuccess, setSuccess] = useState(false);
  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [successResMessage, setSuccessResMessage] = useState("");
  const [isResSuccess, setResSuccess] = useState(false);

  const [list, setList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onPress = () => {
    addressRef.current.onPress();
  };

  const onUpdate = (respData) => {
    setViewAddressForm(false);
    onGetAddressList();
    setResSuccess(true);
    setSuccessResMessage(_.get(respData, "message", ""));
  };

  useEffect(() => {
    onGetAddressList();
  }, []);

  const onGetAddressList = async () => {
    setResError(false);
    handleOpen();
    const response = await UserRepository.getAddressList();
    if (response) {
      if (response?.status_code == 1) {
        setList(_.get(response, "data", []));
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, "message", ""));
      }
      handleClose();
    }
  };

  const onRemoveAddress = async (id) => {
    setResError(false);
    handleOpen();
    const response = await UserRepository.deleteAddress(id);
    if (response) {
      if (response?.status_code == 1) {
        setResError(true);
        setErrorResMessage(_.get(response, "message", ""));
        setSuccess(true);
        onGetAddressList();
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, "message", ""));
      }
      handleClose();
      _.delay(()=>{
        setResError(false);
        setErrorResMessage('');
        setSuccess(false);
      },15000)
    }
   
  };

  return (
    <>
      <Container maxWidth={"lg"}>
        {isResError && (
          <Grid item>
            <Alert severity={isSuccess?"success" :"error"}>{errorResMessage}.</Alert>
          </Grid>
        )}
        <Grid container flexDirection={"column"} rowGap={2}>
          <Grid item>
            <Typography
              color={useTheme().palette.grey[600]}
              variant="subtitle1"
            >
              You can store up to 12 addresses that you prefer to ship to. Your
              default shipping address is the one you registered with. You can
              change this at any time.
            </Typography>
          </Grid>
          <Grid item display={"flex"} justifyContent={"flex-end"}>
            <Box>
              <ButtonComponent
                name={"Add New Address"}
                variant="contained"
                onClick={() => setViewAddressForm(true)}
              />
            </Box>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              {_.map(list, (item, index) => {
                return (
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={`address_${index}`}
                  >
                    <AddressCard 
                    onRemoveAddress={onRemoveAddress}
                    onGetAddressList={onGetAddressList} 
                    item={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <PopupComponent
        maxWidth={"md"}
        actionButtonName={"Create Address "}
        content={<CreateAddress ref={addressRef} onPassResponse={onUpdate} />}
        secondaryBtnName={"Cancel"}
        open={viewAddressForm}
        title={"Create Address"}
        handleClose={() => setViewAddressForm(false)}
        handleAction={() => onPress()}
      />

  <PopupComponent
      maxWidth={"sm"}
      actionButtonName={"Close"}
      content={<SuccessMessage message={successResMessage} />}
      showSecondaryBtn={false}
      open={isResSuccess}
      title={"Address Creation"}
      handleAction={() => {
        setResSuccess(false); 
      }}
      handleClose={() => setResSuccess(false)}
    />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
