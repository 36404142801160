import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
    return `common/${path}`;
}
//Common API Calls
class CommonRepository extends Repository {
    onGetProvinceList = async () => {
        try {
            const data = await this.getData(getPath('system/provinceDropdown'), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };


    onGetDistricList = async (id) => {
        try {
            const data = await this.getData(getPath(`system/districtDropdown/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

   
    onGetCityList = async (id) => {
        try {
            const data = await this.getData(getPath(`system/cityDropdown/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onGetLocationList = async (id) => {
        try {
            const data = await this.getData(getPath(`system/get-locations`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onGetShippingCost = async (params) => {
        try {
            const data = await this.postData(getPath(`shipping-cost`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onContactUs =  async (param)=>{
        try {
            const data = await this.uploadForm(getPath('contact-us'),param);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    onGetRetailerList = async () => {
        try {
            const data = await this.getData(getPath('system/get-us-retailer'), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onGetBannerImage = async (params) => {
        try {
            const data = await this.getData(getPath(`system/my-suite-banner`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };

    onGetCityDetails = async (id) => {
        try {
            const data = await this.getData(getPath(`system/cityDetails/${id}`), {});
            return _.get(data, `data`);
        } catch (error) {
            return { error };
        }
    };
}

export default new CommonRepository("common");
