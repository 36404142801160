// import React, { useState } from "react";
// import MUIDataTable from "mui-datatables";
// import { Box, TableCell, TableRow } from "@mui/material";
// import { ExpandableRowEntry } from "./ExpandableRowEntry";

// export default function TableComponent({
//   columns = [],
//   data = [],
//   title = "",
//   selectableRows = "multiple",
//   search = true,
//   expandableRows = false,
//   renderExpandableRow,
//   onHandleBackDrop = () => {},
//   onLoadDataAgain = () => {},
//   onRowSelectionChange,
//   selectableRowsHeader= false,
//   selectableRowsOnClick = true
// }) {
//   const [expandedRows, setExpandedRows] = useState([]);

//   const dateCount = (date) => {
//     const moment = require("moment");
//     const givenDate = moment(date);
//     const currentDate = moment();
//     return currentDate.diff(givenDate, "days") + 1;
//   };

//   const handleRowExpansionChange = (curExpanded, allExpanded, rowsExpanded) => {
//     setExpandedRows(rowsExpanded);
//   };

//   const handleRowSelectionChange = (curRowSelected, allRowsSelected) => {
//     // Ensure rows remain expanded when selected
//     const selectedRows = allRowsSelected.map(row => row.dataIndex);
//     setExpandedRows(prevExpandedRows => {
//       const newExpandedRows = [...prevExpandedRows];
//       selectedRows.forEach(row => {
//         if (!newExpandedRows.includes(row)) {
//           newExpandedRows.push(row);
//         }
//       });
//       return newExpandedRows;
//     });

//     if (onRowSelectionChange) {
//       onRowSelectionChange(curRowSelected, allRowsSelected);
//     }
//   };
//   // const options = {
//   //   filter: false,
//   //   download: false,
//   //   sort: false,
//   //   print: false,
//   //   viewColumns: false,
//   //   responsive: "simple",
//   //   selectableRows: selectableRows,
//   //   selectableRowsOnClick:selectableRowsOnClick,
//   //   onRowSelectionChange: onRowSelectionChange,
//   //   search: search,
//   //   expandableRows: expandableRows,
//   //   expandableRowsHeader: false,
//   //   expandableRowsOnClick: true,
//   //   customToolbarSelect: () => null,
//   //   Expandable: (dataIndex, expandedRows) => {
//   //     return true;
//   //   },
//   //   rowsExpanded: [],
//   //   renderExpandableRow: (rowData, rowMeta) => {
//   //     return (
//   //       <ExpandableRowEntry
//   //         onLoadDataAgain={onLoadDataAgain}
//   //         onHandleBackDrop={onHandleBackDrop}
//   //         rowData={rowData}
//   //         rowMeta={rowMeta}
//   //       />
//   //     );
//   //   },

//   //   onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
//   //     console.log(curExpanded, allExpanded, rowsExpanded);
//   //   },
//   //   setRowProps: (row, dataIndex, rowIndex) => {
//   //     const isConditionMet = dateCount(row[7]) >= 30;
//   //     return {
//   //       style: {
//   //         backgroundColor: isConditionMet ? '#FF777780' : 'white',
//   //         color: isConditionMet ? "#ffffff" : "#000000"
//   //       },
//   //     };
//   //   },
//   //   selectableRowsHeader:selectableRowsHeader
    
//   // };
//   const options = {
//     filter: false,
//     download: false,
//     sort: false,
//     print: false,
//     viewColumns: false,
//     responsive: "simple",
//     selectableRows: selectableRows,
//     selectableRowsOnClick: selectableRowsOnClick,
//     onRowSelectionChange: handleRowSelectionChange,
//     search: search,
//     expandableRows: expandableRows,
//     expandableRowsHeader: false,
//     expandableRowsOnClick: true,
//     customToolbarSelect: () => null,
//     rowsExpanded: expandedRows,
//     renderExpandableRow: (rowData, rowMeta) => {
//       return (
//         <ExpandableRowEntry
//           onLoadDataAgain={onLoadDataAgain}
//           onHandleBackDrop={onHandleBackDrop}
//           rowData={rowData}
//           rowMeta={rowMeta}
//         />
//       );
//     },
//     onRowExpansionChange: handleRowExpansionChange,
//     setRowProps: (row, dataIndex, rowIndex) => {
//       const isConditionMet = dateCount(row[7]) >= 30;
//       return {
//         style: {
//           backgroundColor: isConditionMet ? '#FF777780' : 'white',
//           color: isConditionMet ? "#ffffff" : "#000000"
//         },
//       };
//     },
//     selectableRowsHeader: selectableRowsHeader
//   };
  
//   return (
//     <MUIDataTable
//       title={title}
//       data={data}
//       columns={columns}
//       options={options}
//     />
//   );
// }

import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box, Checkbox, TableCell, TableRow, useMediaQuery } from "@mui/material";
import { ExpandableRowEntry } from "./ExpandableRowEntry";

export default function TableComponent({
  columns = [],
  data = [],
  title = "",
  selectableRows = "multiple",
  search = true,
  expandableRows = false,
  renderExpandableRow,
  onHandleBackDrop = () => {},
  onLoadDataAgain = () => {},
  onRowSelectionChange,
  selectableRowsHeader = false,
  selectableRowsOnClick = true
}) {
  const [expandedRows, setExpandedRows] = useState([]);

  const dateCount = (date) => {
    const moment = require("moment");
    const givenDate = moment(date);
    const currentDate = moment();
    return currentDate.diff(givenDate, "days") + 1;
  };

  const handleRowExpansionChange = (curExpanded, allExpanded, rowsExpanded) => {
    setExpandedRows(rowsExpanded);
  };

  const handleRowSelectionChange = (curRowSelected, allRowsSelected) => {
    // Ensure rows remain expanded when selected
    const selectedRows = allRowsSelected.map(row => row.dataIndex);
    setExpandedRows(prevExpandedRows => {
      // Ensure prevExpandedRows is always an array
      if (!Array.isArray(prevExpandedRows)) {
        prevExpandedRows = [];
      }
      const newExpandedRows = new Set(prevExpandedRows);
      selectedRows.forEach(row => newExpandedRows.add(row));
      return Array.from(newExpandedRows);
    });

    if (onRowSelectionChange) {
      onRowSelectionChange(curRowSelected, allRowsSelected);
    }
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  const options = {
    filter: false,
    download: false,
    sort: false,
    print: false,
    viewColumns: false,
    responsive: "simple",
    selectableRows: selectableRows,
    selectableRowsOnClick: selectableRowsOnClick,
    onRowSelectionChange: handleRowSelectionChange,
    search: search,
    expandableRows: expandableRows,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    customToolbarSelect: () => null,
    rowsExpanded: expandedRows,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandableRowEntry
          onLoadDataAgain={onLoadDataAgain}
          onHandleBackDrop={onHandleBackDrop}
          rowData={rowData}
          rowMeta={rowMeta}
          title={title}
        />
      );
    },
    onRowExpansionChange: handleRowExpansionChange,
    setRowProps: (row, dataIndex, rowIndex) => {
      const isConditionMet = dateCount(row[7]) >= 30;
      return {
        style: {
          backgroundColor: isConditionMet && title != 'View All' ? '#FF777780' : 'white',
          color: isConditionMet ? "#ffffff" : "#000000"
        },
      };
    },
    selectableRowsHeader: selectableRowsHeader,
  };

  return (
    <Box
    sx={{
      "& .MuiTableHead-root": {
        display: isMobile ? "none" : "table-header-group",
      },
      "& .MuiTable-root": {
        tableLayout: isMobile ? "none" : "fixed", 
      },
      "& .MuiTableCell-root": {
        display: isMobile ? "block" : "table-cell", 
        width: isMobile ? "100%" : "auto",
        textAlign: isMobile ? "left" : "inherit",
        padding: isMobile ? "8px 16px" : "10px",
      },
      "& .MuiTableRow-root": {
        borderBottom: isMobile ? "1px solid #ddd" : "none",
        marginBottom: isMobile ? "8px" : "0",
        display: isMobile ? "block" : "table-row", // Make rows stack vertically
      },
      "& .MuiTableCell-root:first-of-type": {
        width: selectableRows == 'multiple' && !isMobile ? "75px": expandableRows && !isMobile ? "40px": "auto",
        maxWidth: selectableRows == 'multiple' && !isMobile ? "75px": expandableRows && !isMobile ? "40px": "auto",
      },
    }}
  >
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    />
</Box>
  );
}

