import React, { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { PopupComponent } from "../../../atoms";
import { ViewPhoto } from "../ViewPhoto/ViewPhoto";
import _ from "lodash";
import { PhotoAlbum } from "@mui/icons-material";
import { SuccessMessage } from "../SuccessMessage/SuccessMessage";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export const PackageExpandable = ({
  data,
  onHandleBackDrop = () => { },
  onLoadDataAgain = { onLoadDataAgain },
}) => {
  const [viewSinglePhoto, setViewSinglePhoto] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ image: "", fileName: "" });

  const [list, setList] = useState([]);
  const [allData, setAllData] = useState({});
  const [packages, setPackages] = useState([]);


  useEffect(() => {
    setList(_.get(data, "[2]", []));
    const item = _.get(data, "[3].item");
    const packageItemDetails = _.get(data, "[3].packageItemDetails");

    setPackages(packageItemDetails);
    setAllData({ ...item, packageItemDetails });
  }, [data]);

  useEffect(() => {
  }, [allData]);

  const onClose = () => {
    setViewSinglePhoto(false);
    setSelectedItem({ image: "", fileName: "" });
  };


  return (
    <>

      <Stack spacing={1}>
        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Package Details
        </Typography>

        <Typography variant="subtitle1" fontFamily={"Source Sans Pro"}>
          <span style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}>
            To:
          </span>
          {` ${_.get(allData, "customerName", " ")}`}
        </Typography>
        {_.map(packages, (dataItem, index) => {
          return (
            <Box
              key={index + 1}
              sx={{
                paddingInline: 2,
                p: 1,
                borderRadius: 2,
                bgcolor: "#C8ACD630",
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}
                    >
                      Description:
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    {_.get(dataItem, "Description", "")}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{ fontFamily: "Source Sans Pro", fontWeight: 600 }}
                    >
                      Qty:
                    </span>
                    <br />
                    {_.get(dataItem, "NumberOfPieces", "")}
                  </Typography>
                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={2} xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Source Sans Pro"}
                  >
                    <span
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: 600,
                      }}
                    >
                      Total Line Value:
                    </span>
                    <br />${" "}
                    {_.get(dataItem, "NumberOfPieces", "") *
                      _.get(dataItem, "ItemValueInCurrency", "")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}

        <Divider />
        {list.length > 0 ? (<>
        <Typography
          variant="h6"
          fontFamily={"Source Sans Pro"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Received Photos
        </Typography>

        <Grid container spacing={1}>
          {_.map(list, (item, index) => {
            const image =
              `data:image/png;base64,` + _.get(item, "Document", "");
            const fileName = _.get(item, "FileName", "");

            return (
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={3}
                key={index + 1}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (fileName || fileName?.length > 0) {
                    setViewSinglePhoto(true);
                    setSelectedItem({ image, fileName });
                  }
                }}
              >
                {!fileName || fileName?.length <= 0 ? (
                  <Avatar sx={{ width: "100%", height: 80, borderRadius: 2 }}>
                    <PhotoAlbum />
                  </Avatar>
                ) : (
                  <Avatar
                    src={image}
                    sx={{ width: "100%", height: 80, borderRadius: 2 }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        </>) :''}
      </Stack>

      <PopupComponent
        open={viewSinglePhoto}
        handleClose={onClose}
        handleAction={onClose}
        maxWidth={"md"}
        title={"Image_name.jpg"}
        showBottomBtns={false}
        showSecondaryBtn={false}
        content={<ViewPhoto data={selectedItem} />}
      />

    </>
  );
};
