import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { IMAGES } from "../../assets";
import { theme } from "../../style/Theme/Theme";
import { HomePageCard } from "../../components/modules";
import { ButtonComponent, SlideShowComponent } from "../../components/atoms";
import { Link } from "react-router-dom";
import { UTIL_METHODS } from "../../utils";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Stack } from "@mui/material";

export default function HomePage() {
  let access_token = UTIL_METHODS.getToken();
  const serviceList = [
    { img: IMAGES.HOME.HOME_SIGNUP, text: "Sign up and get a USA address." },
    { img: IMAGES.HOME.HOME_TAX_FREE_ADDRESS, text: "Tax-free USA Address" },
    { img: IMAGES.HOME.HOME_FREE_PACKAGE, text: "Free package consolidation" },
    {
      img: IMAGES.HOME.HOME_INTERNATIONAL_BRANDS,
      text: "International brands straight from the USA",
    },
    { img: IMAGES.HOME.HOME_SHIPPING_RATES, text: "Discounted shipping rates" },
    { img: IMAGES.HOME.HOME_STORAGE, text: "Free storage up to 30 days" },
  ];
  return (
    <div className="home-page-container">
      <Grid container rowGap={10}>
        <Grid item xs={12}>
          <div className="top-section-container">
            <Container>
              <Grid container alignItems={"center"} pt={11} pb={11}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12} >
                  <Box p={5}>
                  <Box mb={3}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 600,
                        fontFamily: "Source sans pro",
                        textAlign: {
                          xl: "left",
                          lg: "left",
                          md: "left",
                          sm: "left",
                          xs: "left",
                        },
                      }}
                      color={theme.palette.primary.main}
                    >
                      Shop what you love from US stores and ship it to Sri
                      Lanka.
                    </Typography>
                      </Box>
                      <Box mb={3}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Source sans pro",
                        textAlign: {
                          xl: "left",
                          lg: "left",
                          md: "left",
                          sm: "left",
                          xs: "left",
                        },
                      }}
                      color={useTheme().status.smallTextColor}
                    >
                      Get all the brands you love from the USA!
                    </Typography>
                    </Box>
                    <Button size="medium" endIcon={<YouTubeIcon />} href={'/how-it-works'} sx={{backgroundColor:'#B1EF06', color:'white', fontSize:'12px', fontWeight:'600', padding:'5px 18px',
                     "&:hover": {
                      backgroundColor: "#c2f047", // Keeps the same background color
                      color: "white", // Keeps the same text color
                    },}}  >
                    LEARN HOW IT WORKS  </Button>
                  
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <img
                    src={IMAGES.LOGO.BANNER_01}
                    alt="banner01"
                    className="home-banner-img"
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <Grid container spacing={2}>
              {serviceList?.map((item, index) => {
                return (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    key={index + 1}
                  >
                    <HomePageCard img={item?.img} text={item.text} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <div className="last-container-home top-section-container">
            <Container>
              <Grid container rowGap={4} flexDirection={"column"} pt={4}>
                <Grid item>
                  <Typography
                    variant="h4"
                    sx={{
                      color: useTheme().palette.primary.main,
                      fontWeight: 600,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                    Shop tax-free & ship from
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: useTheme().palette.primary.main,
                      fontWeight: 600,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                    thousands of US retailers
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: useTheme().status.smallTextColor,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                    Want to shop from US websites like Amazon, eBay, Ralph
                    Lauren, Shop Disney, Carter's,
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: useTheme().status.smallTextColor,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                    Walmart, RockAuto, 6pm and more?
                  </Typography>
               
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: useTheme().status.smallTextColor,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                       {access_token == "" || access_token == null ? (<>
                        <a href="/sign-up" 
                      style={{
                        color: '#A9A9A9', 
                        textDecoration: 'none', 
                        cursor: 'pointer',
                      }}
                      onMouseOver={(e) => e.target.style.color = '#572682'} 
                      onMouseOut={(e) => e.target.style.color = '#A9A9A9'}
                    >Sign up</a> to get a Presto Worldwide USA shipping address and
                    start shopping now!
                       </>)
                    :''}
                  </Typography>
                  
                </Grid>
                <Grid item xs={12}>
                  <SlideShowComponent />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h4"
                    sx={{
                      color: useTheme().palette.primary.main,
                      fontWeight: 600,

                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                    Ready to shop & ship?
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: useTheme().status.smallTextColor,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                    How much will it cost to shop a package from the United
                    States to your doorstep? Use our <a href="/shipping-calculator" 
                      style={{
                        color: '#A9A9A9', 
                        textDecoration: 'none', 
                        cursor: 'pointer',
                      }}
                      onMouseOver={(e) => e.target.style.color = '#572682'} 
                      onMouseOut={(e) => e.target.style.color = '#A9A9A9'}
                    >shipping calculator</a> to find
                    the best rates!
                  </Typography>
                </Grid>
                
                  <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: useTheme().status.smallTextColor,
                      fontFamily: "Source sans pro",
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "left",
                      },
                    }}
                  >
                   {access_token == "" || access_token == null ? 
                   "Sign up to receive a USA address and start shopping for your favourite US brands today." :''}
                  </Typography>
                </Grid>
                {access_token == "" || access_token == null ? 
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Source sans pro",
                  }}
                >
                  <Link to={"/sign-up"}>
                    <ButtonComponent
                      size="large"
                      variant="contained"
                      name="Sign Up Now "
                    />
                  </Link>
                </Grid>
                :''}
              </Grid>
            </Container>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
