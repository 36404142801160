import country from "./countries.json";
import _ from "lodash";
const isLive = false;
const BASE_URI = isLive ? "" : "";

const PRIVATE_USER = 1;
const PUBLIC_USER = 2;

export const MINIMUM_PASSWORD_CHARACTERS = 8;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
export const PHONE_REGEX = /^(07\d{8}|7\d{8})$/;
export const FIND_IS_NUMBER_INCLUDED_REGEX = /\d+/;
export const Find_SPECIAL_CHARACTER = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export const ONLY_NUMBERS = /^\d+$/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const CREDENTIALS = {
  BASE_URI,
  PRIVATE_USER,
  PUBLIC_USER,
};

export default CREDENTIALS;

export const getText = (e) => e.target.value;

export const setText = (text) => {
  return {
    target: {
      value: text
    }
  }
}

export const getChecked = (e) => e.target?.checked;

export const isUpperCase = (string) => /[A-Z]/.test(string);

export const isLowerCase = (string) => /[a-z]/.test(string);

export const isSpecialCharacter = (string) => Find_SPECIAL_CHARACTER.test(string);

export const isNumber = (string) => FIND_IS_NUMBER_INCLUDED_REGEX.test(string);

export const AUTH_SCREEN_STATES = {
  LOGIN: 1,
  FORGOTPASSWORD:2,
  VERIFY:3,
  RESET_PASSWORD:4
}


export const onGetCurrencySymble = (code) => {
  const data = _.find(country, i => _.get(i, 'currency.code', 'USD') == code);
  return _.get(data, 'currency.symbol', '£');
}