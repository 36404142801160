import React from "react";
import { Container } from "@mui/material";

export default function FailurePage() {

  return (
    <Container sx={{mt:5, mb:5}}>
       <div>Payment Failed. Please try again.</div>
    </Container>
  );
}
