import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";


export const AboutUsPage = () => {
    return(<Container sx={{mt:2, mb:2}}>
        <Stack spacing={3}>
            <Box>
            <Typography
                    variant="h6"
                    fontFamily={"Source Sans Pro"}
                    color={"#572682"}
                    fontWeight={600}
                  >
                    Welcome to Presto Worldwide
                  </Typography>
            </Box>
            <Box>
            <Typography
                    variant="subtitle1"
                    fontFamily={"Source Sans Pro"}
                    color={"GrayText"}
                    fontWeight={500}
                  >
                    A simple, reliable and efficient way to shop your favourite US brands online and ship it directly to your doorstep anywhere in Sri Lanka.
                  </Typography>
            </Box>
            <Box>
            <Typography
                    variant="h6"
                    fontFamily={"Source Sans Pro"}
                    color={"#572682"}
                    fontWeight={600}
                  >
                    Who are we?
                  </Typography>
            </Box>
            <Box>
            <Typography
                    variant="subtitle1"
                    fontFamily={"Source Sans Pro"}
                    color={"GrayText"}
                    fontWeight={500}
                    sx={{ textAlign: "justify" }}
                  >
PRESTO WORLDWIDE allows Sri Lankan consumers to shop for their favourite US brands online and ship it to Sri Lanka within 09 days or less. There's no setup fee, you receive a tax-free US shipping address, free package consolidation and discounted shipping rates. Sign up on Presto Worldwide and enjoy shopping in the USA!                  </Typography>
            </Box>
        </Stack>
    </Container>)
}