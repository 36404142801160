import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle  } from 'react'
import { Box, Grid, Typography, useTheme, Alert, Backdrop, CircularProgress } from '@mui/material'
import { ButtonComponent, CheckboxComponent, DropdownComponent, InputComponent } from '../../../atoms'
import { EMAIL_REGEX, MINIMUM_PASSWORD_CHARACTERS, PHONE_REGEX, getChecked, getText, setText } from '../../../../utils/Credentials';
import { AuthRepository, CommonRepositoty, UserRepository } from '../../../../repository';
import _ from "lodash";

const CreateAddress=({onPassResponse=()=>{}},ref)=> {
    const [fName, setFName] = useState('');
    const [isFNameValid, setIsFNameValid] = useState(false);
    const [fNameError, setFNameError] = useState(false);

    const [lName, setLName] = useState('');
    const [isLNameValid, setIsLNameValid] = useState(false);
    const [lNameError, setLNameError] = useState(false);


    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [errorPhone, setPhoneError] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('Mobile number is required');

    const [addressOne, setAddressOne] = useState('');
    const [isAddressOneValid, setIsAddressOneValid] = useState(false);
    const [addressOneError, setAddressOneError] = useState(false);

    const [addressTwo, setAddressTwo] = useState('');
    const [isAddressTwoValid, setIsAddressTwoValid] = useState(false);
    const [addressTwoError, setAddressTwoError] = useState(false);

    const [province, setProvince] = useState(0);
    const [isProvinceValid, setIsProvinceValid] = useState(false);
    const [provinceError, setProvinceError] = useState(false);

    const [distric, setDistric] = useState(0);
    const [isDistricValid, setIsDistricValid] = useState(false);
    const [districError, setDistricError] = useState(false);

    const [city, setCity] = useState(0);
    const [isCityValid, setIsCityValid] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [zip, setZip] = useState('');
    const [isZipValid, setIsZipValid] = useState(false);
    const [zipError, setZipError] = useState(false);

    const [provinceList, setProvinceList] = useState([]);
    const [districList, setDistricList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isResError, setResError] = useState(false);
    const [errorResMessage, setErrorResMessage] = useState('');

    const [open, setOpen] = React.useState(false);

    const [addressName, setAddressName] = useState("");
    const [isAddressNameValid, setIsAddressNameValid] = useState(false);
    const [isAddressNameError, setAddressNameError] = useState(false);

    const [countryId, setCountryId] = useState(0);
    const [isCountryIdValid, setIsCountryIdValid] = useState(false);
    const [isCountryIdError, setIsCountryIdError] = useState(false);

    useImperativeHandle(ref, () => ({
        onPress() {
            onAddAddressUpdate();
        }
    }))


    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        onCallProvince()
    }, []);

    const onCallProvince = async () => {
        const data = await CommonRepositoty.onGetProvinceList();
        if (data?.status_code == 1) {
            setError(false);
            setProvinceList(_.get(data, 'data', []))
        } else {
            setError(true);
        }
        setErrorMessage(_.get(data, 'message', ''))
    };


    const onCallDistric = async (provinceId) => {
        const data = await CommonRepositoty.onGetDistricList(provinceId);

        if (data?.status_code == 1) {
            setError(false);
            setDistricList(_.get(data, 'data', []))
        } else {
            setError(true);
        }
        setErrorMessage(_.get(data, 'message', ''))
    };


    const onCallCity = async (districId) => {
        const data = await CommonRepositoty.onGetCityList(districId);
        if (data?.status_code == 1) {
            setError(false);
            setCityList(_.get(data, 'data', []))
        } else {
            setError(true);
        }
        setErrorMessage(_.get(data, 'message', ''))
    };

    const getCityDetails = async (cityId) => {
        const data = await CommonRepositoty.onGetCityDetails(cityId);
        if (data?.status_code == 1) {
            setError(false);
            const text = setText(data?.data[0]?.postal_code);
            onChangeZip(text);

        } else {
            setError(true);
        }
        setErrorMessage(_.get(data, 'message', ''))
    };

    const onChangeFirstName = (e) => {
        const text = getText(e)
        setFName(text);
        setFNameError(false);
        setIsFNameValid(text?.length > 0)
    }

    const onChangeLastName = (e) => {
        const text = getText(e)
        setLName(text);
        setLNameError(false);
        setIsLNameValid(text?.length > 0)
    }


    const onChangePhone = (e) => {
        const text = getText(e);
        const reg = PHONE_REGEX;
        let isValid = false;
        if (text.length > 3) {
            isValid = reg.test(text);
        }
        setPhone(text);
        setPhoneError(!isValid);
        setIsPhoneValid(isValid);
    
        if(isValid == false){
          setPhoneErrorMessage('Mobile number is invalid');
        }else{
          setPhoneErrorMessage('');
        }
      }

    const onChangeAddressName = (e) => {
        const text = getText(e)
        setAddressName(text);
        setAddressNameError(false);
        setIsAddressNameValid(text?.length > 0)
    }

    const onChangeFirstAddress = (e) => {
        const text = getText(e)
        setAddressOne(text);
        setAddressNameError(false);
        setIsAddressOneValid(text?.length > 0)
    }

    const onChangeLastAddress = (e) => {
        const text = getText(e)
        setAddressTwo(text);
        setAddressTwoError(false);
        setIsAddressTwoValid(text?.length > 0)
    }


    const onChangeDistric = (e) => {
        onChangeCity(setText(0))
        setCityList([])
        onChangeZip(setText(''))
        const text = getText(e)
        setDistric(text);
        setDistricError(false);
        setIsDistricValid(text > 0);
        onCallCity(text)
    }

    const onChangeCity = (e) => {
        const text = getText(e)
        setCity(text);
        setCityError(false);
        setIsCityValid(text > 0)
        getCityDetails(text);
    }


    const onChangeProvince = (e) => {
        setDistricList([]);
        onChangeDistric(setText(0))
        onChangeZip(setText(''))
        const text = getText(e)
        setProvince(text);
        setProvinceError(false);
        setIsProvinceValid(true);
        onCallDistric(text)
    }

    const onChangeZip = (e) => {
        const text = getText(e)
        setZip(text);
        setZipError(false);
        setIsZipValid(text?.length > 0)
    }


    const onAddAddressUpdate = async () => {
        setResError(false)
        if ( isFNameValid && isLNameValid && isCityValid && isDistricValid && isPhoneValid && isAddressOneValid && isZipValid && isAddressNameValid && isProvinceValid) {

            let data = {
                label: addressName,
                first_name: fName,
                last_name: lName,     
                mobile: phone,
                address_line_one: addressOne,
                country_id: "1",
                province_id: province,
                district_id: distric,
                city_id: city,
                zip_code: zip
            }
            if (isAddressTwoValid) {
                data = { ...data, address_line_two: addressTwo }
            }
            handleOpen()
            const response = await UserRepository.addAddress(data);
            if (response) {
                if (response?.status_code == 1) {
                    onPassResponse(response);
                    onClean()
                } else {
                    setResError(true);
                    setErrorResMessage(_.get(response, 'message', ''));
                }
                handleClose()
            }
        } else {
            if (!isZipValid) {
                setZipError(true)
            }
            if (!isAddressTwoValid) {
                setAddressTwoError(true)
            }
            if (!isAddressOneValid) {
                setAddressOneError(true)
            }
            if (!isPhoneValid) {
                setPhoneError(true)
            }
            if (!isProvinceValid) {
                setProvinceError(true)
            }
            if (!isDistricValid) {
                setDistricError(true)
            }
            if (!isCityValid) {
                setCityError(true)
            }
            if (!isLNameValid) {
                setLNameError(true)
            }
            if (!isFNameValid) {
                setFNameError(true)
            }
            if (!isAddressNameValid) {
                setAddressNameError(true)
            }
           
        }
    }


    const onClean = ()=>{
        const text = setText('');
        onChangeFirstAddress(text);
        onChangeLastAddress(text);
        onChangePhone(text);
        onChangeFirstName(text);
        onChangeLastName(text);
        onChangeZip(text);
        onChangeProvince(text);
    }

    return (

        <>
            <Grid container flexDirection={'column'} rowSpacing={2}>
                {isResError && <Grid item>
                    <Alert severity="error">{errorResMessage}.</Alert>
                </Grid>}

                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputComponent
                                value={addressName}
                                isError={isAddressNameError}
                                error={'Address name is required'}
                                onChange={onChangeAddressName}
                                label='Address Name'
                                type={'text'} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputComponent
                                // value={phone}
                                isError={errorPhone}
                                error={phoneErrorMessage}
                                onChange={onChangePhone}
                                label='Mobile (7XXXXXXXX)'
                                type={'text'} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputComponent
                                isError={fNameError}
                                error={'First Name is required'}
                                onChange={onChangeFirstName}
                                label='First Name'
                                type={'text'} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputComponent
                                isError={lNameError}
                                error={'Last Name is required'}
                                onChange={onChangeLastName}
                                label='Last Name'
                                type={'text'}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputComponent
                                isError={addressOneError}
                                error={'Street1 is required'}
                                onChange={onChangeFirstAddress}
                                label='Address 01'
                                type={'text'} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputComponent
                                onChange={onChangeLastAddress}
                                label='Address 02'
                                type={'text'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <DropdownComponent
                                isError={provinceError}
                                disabled={false}
                                dropdownList={provinceList}
                                onChange={onChangeProvince}
                                error={'Province is required'}
                                label='Province' />
                        </Grid>
                        <Grid item xs={3}>
                            <DropdownComponent
                                isError={districError}
                                dropdownList={districList}
                                disabled={!isProvinceValid}
                                onChange={onChangeDistric}
                                error={'District is required'}
                                label='District' />
                        </Grid>
                        <Grid item xs={3}>
                            <DropdownComponent
                                isError={cityError}
                                dropdownList={cityList}
                                disabled={!isDistricValid}
                                onChange={onChangeCity}
                                error={'City is required'}
                                label='City' />
                        </Grid>
                        <Grid item xs={2}>
                            <InputComponent
                                value={zip}
                                isError={zipError}
                                error={'Postal Code is required'}
                                onChange={onChangeZip}
                                label='ZIP'
                                type={'text'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                       
                      
                    </Grid>
                </Grid>



                {isError && <Grid item>
                    <Alert severity="error">{errorMessage}.</Alert>
                </Grid>}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid></>

    )
}

export default forwardRef(CreateAddress)