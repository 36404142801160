import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, Container, Button } from "@mui/material";


export default function ErrorPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const cause = queryParams.get("cause") || "Unknown Cause";
  const explanation = queryParams.get("explanation") || "No explanation provided.";
  const field = queryParams.get("field") || "Unknown Field";
  const validationType = queryParams.get("validationType") || "Unknown Validation Type";
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container sx={{ mt: 5, mb: 5 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f8d7da"
      >
        <Card sx={{ maxWidth: 600, p: 3, bgcolor: "#ffffff" }}>
          <CardContent>
            <Typography variant="h4" color="error" gutterBottom>
              Error: {cause}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Explanation:</strong> {decodeURIComponent(explanation)}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Field:</strong> {field}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Validation Type:</strong> {validationType}
            </Typography>
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={goBack}>
                Go Back
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
