import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./routes/PublicRoutes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoutes/PrivateRoute";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  AboutUsPage,
  Authentication,
  CalculatorPage,
  ContactUsPage,
  CreateShippingAddressPage,
  FaqPage,
  HistoryPage,
  HomePage,
  MySuitPage,
  OrderDetailsPage,
  PaymentPage,
  PrivacyPolicyPage,
  ProfilePage,
  SignUpPage,
  TermsNConditionsPage,
  TutorialsPage,
  OrderTrackingPage,
  PaymentReturnPage,
  PaymentFailurePage,
  PaymentErrorPage,
} from "./pages";
import Screen from "./Screen/Screen";
import { theme } from "./style/Theme/Theme";
import HeadingScreen from "./Screen/HeadingScreen";
import { ProhibitedItemsPage } from "./pages/ProhibitedItems/ProhibitedItemsPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route element={<Screen isAuth={false} />}>
              <Route element={<PublicRoute />}>
                <Route index element={<HomePage />} />
                <Route path="/sign-in" element={<Authentication />} />
                <Route path="/sign-up" element={<SignUpPage />} />
              </Route>
            </Route>

            <Route element={<Screen isAuth={true} />}>
              <Route element={<PrivateRoute />}>
                <Route path="/my-suite" element={<MySuitPage />} />
                <Route path="/ship-request-page" element={<PaymentPage />} />
                <Route
                  path="/create-shipping-address"
                  element={<CreateShippingAddressPage />}
                />
                <Route
                  path="/shipping_calculator"
                  element={<CalculatorPage />}
                />
                <Route path="/contact_us" element={<ContactUsPage />} />
                <Route path="/payment-response" element={<PaymentReturnPage />}/>
                <Route path="/payment-failure" element={<PaymentFailurePage />}/>
                <Route path="/errorCallback" element={<PaymentErrorPage />}/>
              </Route>
            </Route>

            <Route element={<HeadingScreen />}>
              <Route element={<PublicRoute />}>
                <Route
                  path="/shipping-calculator"
                  element={<CalculatorPage />}
                />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/how-it-works" element={<TutorialsPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/terms-and-conditions" element={<TermsNConditionsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/prohibited-items" element={<ProhibitedItemsPage />} />
              </Route>

            </Route>

            <Route element={<HeadingScreen isAuth={true} />}>
              <Route element={<PrivateRoute />}>
                <Route path="/my-account" element={<ProfilePage />} />
                <Route path="/history/*" element={<HistoryPage />} />
                <Route path="/order-details" element={<OrderDetailsPage />} />
                <Route path="/order-tracking" element={<OrderTrackingPage />} />
              
              </Route>
            </Route>
          </Route>
        </Routes>
        <CssBaseline />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
